import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ //const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class ExpenseService {
    
    async createExpense(data) {
        try {
            const res = await axios.post(API_URL + 'expenses', data,
            {
                headers: AuthService.authHeaderForm()
            })
            console.log(res.data)
            return res.data
            
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }

    }
    async getExpenseByID(id) {
        try {
            const res = await axios.get(API_URL + 'expenses/' + id,
            {
                headers: AuthService.authHeader()
            })
            console.log(res.data)
            return res.data
            
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }

    }

    async getAllExpenses(account, business_unit, category, sub_category, dateFilterSelected, start_date, end_date, gst_tds, location, payment_mode) {
        try {
            const res = await axios.get(API_URL + 'expenses?account=' + account + '&business_unit=' + business_unit + '&category=' + category + '&sub_category=' + sub_category + '&dateFilterSelected=' + dateFilterSelected + '&start_date=' + start_date + '&end_date=' + end_date + '&gst_tds=' + gst_tds + '&location=' + location + '&payment_mode=' + payment_mode,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async updateExpense(id,data) {
        try {
            const res = await axios.put(API_URL + 'expenses/' + id, data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async editExpense(id,data) {
        try {
            const res = await axios.put(API_URL + 'expenses/edit/' + id, data,
            { 
                headers: AuthService.authHeaderForm()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async deleteExpense(id) {
        try {
            const res = await axios.delete(API_URL + 'expenses/' + id,
            { 
                headers: AuthService.authHeaderForm()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    
    async getExpenseProjectList() {
        try {
            const res = await axios.get(API_URL + 'expenses/projects/list',
            {
                headers: AuthService.authHeader()
            })
            console.log(res.data)
            return res
            
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }

    }

    
    

}

export default new ExpenseService();